import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../layouts/main/Layout';
import ContactForm from '../components/ContactForm/ContactForm';
import SEO from '../util/SEO/SEO';

const contact = ({ ...props }) => {
  const intl = useIntl();

  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <SEO
        locale={intl.locale}
        title={intl.formatMessage({ id: 'contact.SEOtitle' })}
        description={intl.formatMessage({ id: 'contact.SEODescription' })}
        keywords={intl.formatMessage({ id: 'SEOkeywords.keywords' })}
      />
      <ContactForm />
    </Layout>
  );
};

export default contact;
