/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Image from '../../util/Image';
import contact from '../../assets/images/contact/contact.json';
import Spinner from '../Spinner/Spinner';
import Icon from '../../assets/icons';


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, <FormattedMessage id="contact.errorNameShort" />)
    .max(50, <FormattedMessage id="contact.errorNameLong" />)
    .required(<FormattedMessage id="contact.errorNameRequired" />),
  email: Yup.string()
    .email(<FormattedMessage id="contact.errorEmailInvalid" />)
    .required(<FormattedMessage id="contact.errorEmailRequired" />),
  message: Yup.string()
    .required(<FormattedMessage id="contact.errorMessageRequired" />),
});

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const ContactForm = () => {
  const [isResolved, setResolving] = useState(false);
  const [isRejected, setRejecting] = useState(false);

  const onSubmit = async (values, {
    setSubmitting, resetForm,
  }) => {
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact-form',
          ...values,
        }),
      });
      setResolving(true);
      setTimeout(() => {
        setResolving(false);
      }, 2000);
      resetForm({});
      setSubmitting(false);
    } catch (error) {
      setRejecting(true);
      setTimeout(() => {
        setRejecting(false);
      }, 2000);
      setSubmitting(false);
    }
  };

  let submitButtonClass = 'btn btn-primary';
  let submitButtonContent = <FormattedMessage id="contact.submit" />;

  if (isResolved) {
    submitButtonClass = 'btn btn-success';
    submitButtonContent = <Icon name="Tick" className="submit-icon" />;
  } else if (isRejected) {
    submitButtonClass = 'btn btn-error';
    submitButtonContent = <Icon name="Close" className="submit-icon" />;
  } else {
    submitButtonClass = 'btn btn-primary';
    submitButtonContent = <FormattedMessage id="contact.submit" />;
  }


  const {
    contactImageSrc,
    contactImageHorizontalSrc,
    contactImageSquareSrc,
    contactImageAlt,
  } = contact.contact;
  return (
    <section className="contact">
      <div className="contact-image-wrapper">
        <Image className="contact-img" filename={contactImageSrc} alt={contactImageAlt} />
        <Image className="contact-img-horizontal" filename={contactImageHorizontalSrc} alt={contactImageAlt} />
        <Image className="contact-img-square" filename={contactImageSquareSrc} alt={contactImageAlt} />
      </div>
      <article className="contact-article">
        <h1>
          <FormattedMessage id="contact.header" />
        </h1>
        <div className="contact-text">
          <p>
            <FormattedMessage id="contact.description1" />
            <a href="mailto:anna@sugarly.co" aria-label="contact email"><FormattedMessage id="contact.descriptionEmail" /></a>
          </p>
        </div>
        <div className="form-wrapper">
          <Formik
            initialValues={{
              'bot-field': '',
              'form-name': 'contact-form',
              name: '',
              email: '',
              message: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({
              isSubmitting,
              touched,
              errors,
              handleSubmit,
            }) => (
              <Form
                name="contact-form"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <div className="field_container">
                  <label className="label" htmlFor="name"><FormattedMessage id="contact.name" /></label>
                  <Field
                    id="name"
                    type="text"
                    name="name"
                    className="field"
                    aria-label="Your name"
                  />
                  {touched.name && errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="field_container">
                  <label className="label" htmlFor="email"><FormattedMessage id="contact.email" /></label>
                  <Field
                    id="email"
                    type="text"
                    name="email"
                    className="field"
                    aria-label="Your email address"
                  />
                  {touched.email && errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className="field_container textarea-container">
                  <label className="label" htmlFor="message"><FormattedMessage id="contact.message" /></label>
                  <Field
                    id="message"
                    name="message"
                    component="textarea"
                    rows="6"
                    className="field textarea"
                    aria-label="Your text message"
                  />
                  {touched.message && errors.message && <span className="error">{errors.message}</span>}
                </div>
                <div>
                  <button aria-label="submit form" className={isSubmitting ? 'btn btn-primary btn-contact' : submitButtonClass} type="submit" name="submit">
                    {isSubmitting ? <Spinner />
                      : submitButtonContent}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </article>
    </section>
  );
};

export default ContactForm;
